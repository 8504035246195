export default [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "/about/",
  },
  {
    id: 3,
    text: "services",
    url: "/services/",
  },
  {
    id: 4,
    text: "portfolio",
    url: "/portfolio/",
  },
  {
    id: 5,
    text: "blog",
    url: "/blog/",
  },
  {
    id: 6,
    text: "newsletter",
    url: "/newsletter/",
  },
  {
    id: 7,
    text: "contact",
    url: "/contact/",
  },
]
